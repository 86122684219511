* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Cormorant Upright', serif;
  transition: all 0.3s ease;
}
.main-container {
  width: calc(100% - 90px);
  margin-left: 90px;
}
@media screen and (max-width: 62em) {
  .main-container {
    margin-left: 90px;
    overflow: hidden;
  }
}
@media screen and (max-width: 75em) {
  .main-container {
    margin-left: 0;
    margin-top: 90px;
    width: 100%;
  }
}
@media screen and (max-width: 48em) {
  .main-container {
    margin-left: 0;
    margin-top: 0;
  }
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDs.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y2vV.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDs.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDs.ttf) format('truetype');
}
@font-face {
  font-family: 'Cormorant Upright';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4n.ttf) format('truetype');
}
/* --------------- colors ------------------------ */
.video {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5000;
}
.video video {
  width: 100%;
}
.g-button {
  color: #ffffff;
  font-family: 'Cormorant Upright', serif;
  width: 157px;
  height: 44px;
  background: #006241;
  border: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  cursor: pointer;
}
.g-button:hover {
  background-color: #00754A;
}
.main_menu {
  z-index: 1000;
  position: relative;
}
.main_menu .sidebar {
  height: 100%;
  width: 90px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 4px #d4e9e2;
  left: 0;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template-rows: 4fr 2fr 1fr;
}
.main_menu .sidebar .logo {
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  width: 72px;
}
.main_menu .sidebar .social_media {
  align-self: end;
  display: flex;
  flex-direction: column;
  margin: auto;
  color: #00754A;
}
.main_menu .sidebar .social_media a {
  height: 40px;
  width: 40px;
  border: 2px solid #00754A;
  border-radius: 50%;
  text-align: center;
  margin-top: 1rem;
  display: flex;
  position: relative;
  color: #00754A;
}
.main_menu .sidebar .social_media a:before {
  content: "";
  color: #00754A;
  border: 2px solid #00754A;
  border-radius: 56%;
  position: absolute;
  left: -2px;
  right: 0;
  height: 40px;
  width: 40px;
  top: -2px;
  z-index: -1;
  box-sizing: border-box;
  transition: all 0.3s;
}
.main_menu .sidebar .social_media a img {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
}
.main_menu .sidebar .social_media a:hover {
  border: 0px;
}
.main_menu .sidebar .social_media a:hover:before {
  height: 0px;
  width: 0px;
  left: 50%;
  top: 50%;
  border: 0;
}
.main_menu .sidebar .menu_btn {
  align-self: end;
  margin: auto;
  overflow: hidden;
  position: relative;
  transition: all 0.8s;
}
.main_menu .sidebar .menu_btn:before,
.main_menu .sidebar .menu_btn:after {
  z-index: -1;
  transition: all 0.8s;
  content: "";
  background-color: #006241;
  height: 4px;
  width: 50%;
  position: absolute;
}
.main_menu .sidebar .menu_btn:before {
  top: 0;
  right: 0;
}
.main_menu .sidebar .menu_btn:after {
  bottom: 0;
  left: 0;
}
.main_menu .sidebar .menu_btn button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-indent: -4000px;
  padding: 0;
  height: 24px;
  width: 36px;
  transition: all 0.8s;
  z-index: 4;
}
.main_menu .sidebar .menu_btn button:before {
  content: "";
  background-color: #006241;
  height: 4px;
  width: 100%;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  transition: all 0.8s;
}
.main_menu .sidebar .menu_btn.active:before {
  transform: matrix(-1, 1, 0.6, 1, 1, 1);
}
.main_menu .sidebar .menu_btn.active:after {
  transform: matrix(-1, 1, 0.6, 1, 1, -1);
}
.main_menu .sidebar .menu_btn.active button:before {
  transform: matrix(-1, -1, 2.6, 1, 1, 0);
}
.main_menu .nav {
  height: 100%;
  width: calc(100% - 90px);
  position: fixed;
  right: calc(100% - 90px);
  top: 0;
  background: #fff url("./Assets/menu-bg.png") center no-repeat;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 1fr;
  transition: right 0.8s;
  transition-timing-function: cubic-bezier(0.36, 0.26, 0.02, 1.18);
}
.main_menu .nav.active {
  right: 0;
}
.main_menu .nav ul {
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-top: 160px;
}
.main_menu .nav ul li a {
  font-family: 'Cormorant Upright', serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 110px;
  letter-spacing: 0.03em;
  text-align: left;
  text-decoration: none;
  color: #242424;
}
.main_menu .nav ul li a.active,
.main_menu .nav ul li a:hover {
  color: #006241;
}
@media screen and (max-width: 62em) {
  .main_menu {
    z-index: 1000;
    position: relative;
  }
}
@media screen and (max-width: 75em) {
  .main_menu .sidebar {
    height: 90px;
    width: 100%;
    grid-template-columns: 4fr 2fr 1fr;
    grid-template-rows: auto;
    box-shadow: 4px 0px 4px #d4e9e2;
  }
  .main_menu .sidebar .logo {
    margin: auto;
    width: 100%;
    margin-left: 4rem;
  }
  .main_menu .sidebar .social_media {
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
  }
  .main_menu .sidebar .social_media a {
    margin-top: 0;
  }
  .main_menu .nav {
    width: 100%;
    right: 100%;
  }
}
@media screen and (max-width: 48em) {
  .main_menu .sidebar {
    background: transparent;
    box-shadow: none;
  }
  .main_menu .sidebar .logo {
    margin-left: 1rem;
  }
  .main_menu .sidebar .social_media {
    opacity: 0;
  }
  .main_menu .sidebar .menu_btn {
    margin-right: 2rem;
  }
  .main_menu .sidebar .menu_btn:before,
  .main_menu .sidebar .menu_btn:after {
    background-color: #ffffff;
  }
  .main_menu .sidebar .menu_btn button:before {
    background-color: #ffffff;
  }
  .main_menu .sidebar .menu_btn.active:before {
    background-color: #006241;
  }
  .main_menu .sidebar .menu_btn.active:after {
    background-color: #006241;
  }
  .main_menu .sidebar .menu_btn.active button:before {
    background-color: #006241;
  }
  .main_menu .nav ul li a {
    font-size: 28px;
    line-height: 88px;
  }
}
.interior_page_header {
  position: relative;
  height: 352px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 3fr 1fr;
  align-items: end;
}
.interior_page_header:before {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: "";
  z-index: 1;
}
.interior_page_header img {
  width: 100%;
  max-height: 352px;
  position: absolute;
}
.interior_page_header h1 {
  color: #ffffff;
  z-index: 1;
  grid-column-start: 2;
  grid-row-start: 2;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: left;
}
.testimonials {
  height: 450px;
  background: url("./Assets/testimonial-bg.png") center no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 2fr 5fr 9fr 2fr;
  align-items: center;
}
.testimonials .static_content {
  grid-column-start: 2;
}
.testimonials .static_content .title {
  max-width: 430px;
  margin-top: -50px;
}
.testimonials .static_content .title p {
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: left;
}
.testimonials .carousel.carousel-slider {
  display: grid;
}
.testimonials .carousel-root {
  grid-column-start: 3;
}
.testimonials .carousel-root .c_item {
  padding: 1rem;
}
.testimonials .carousel-root .c_item .testimonial_wrapper {
  display: grid;
  grid-template-columns: 150px auto;
}
.testimonials .carousel-root .c_item .testimonial_wrapper .image_wrapper {
  position: relative;
  width: 100px;
  height: 100px;
}
.testimonials .carousel-root .c_item .testimonial_wrapper .image_wrapper img {
  width: 100px;
  height: 100px;
}
.testimonials .carousel-root .c_item .testimonial_wrapper .image_wrapper:before {
  position: absolute;
  content: "";
  border: 1px solid #ffffff;
  width: 112px;
  height: 112px;
  left: -7px;
  top: -7px;
  border-radius: 50%;
}
.testimonials .carousel-root .c_item .testimonial_wrapper .content .name {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
}
.testimonials .carousel-root .c_item .testimonial_wrapper .content .testimony {
  margin-top: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #AAAAAA;
  font-family: 'Open Sans', sans-serif;
  max-width: 650px;
}
.testimonials .button_wrapper button {
  width: 75px;
  height: 75px;
  border: 1px solid #D4E9E2;
  background-color: transparent;
  margin-top: 1rem;
  margin-right: 2rem;
  cursor: pointer;
}
.testimonials .button_wrapper button.gallery_previous {
  background-image: url("./Assets/testimonial-arrow-left.png");
  background-repeat: no-repeat;
  background-position: 50% 48%;
}
.testimonials .button_wrapper button.gallery_previous:hover {
  background-position: 0% 48%;
}
.testimonials .button_wrapper button.gallery_next {
  background-image: url("./Assets/testimonial-arrow-right.png");
  background-repeat: no-repeat;
  background-position: 50% 57%;
}
.testimonials .button_wrapper button.gallery_next:hover {
  background-position: 100% 57%;
}
@media screen and (max-width: 800px) {
  .testimonials {
    grid-template-columns: 1fr 25fr 1fr;
    grid-template-rows: auto;
    height: auto;
  }
  .testimonials .static_content .title {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    max-width: none;
    margin: auto;
    margin-top: 2rem;
  }
  .testimonials .carousel-root {
    grid-row-start: 2;
    grid-column-start: 2;
  }
  .testimonials .carousel-root .c_item {
    padding-left: 0;
    padding-right: 0;
  }
  .testimonials .carousel-root .c_item .testimonial_wrapper {
    grid-template-columns: 120px auto;
  }
}
.home_collage {
  background: url("./Assets/Collage.png") center no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
}
.home_collage .phone {
  position: absolute;
  right: 100px;
  top: 100px;
  font-family: 'Cormorant Upright', serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: left;
  z-index: 2;
}
.home_collage .phone a {
  text-decoration: none;
  color: #ffffff;
}
.home_collage .carousel {
  height: 100%;
  z-index: 1;
}
.home_collage .carousel .carousel-root,
.home_collage .carousel .carousel .slider-wrapper,
.home_collage .carousel .slider {
  height: 100%;
}
.home_collage .carousel .control-dots {
  bottom: 50px;
  text-align: right;
  padding-right: 6rem;
}
.home_collage .carousel .control-dots .dot {
  margin: 0 16px;
  position: relative;
}
.home_collage .carousel .control-dots .dot:hover:before,
.home_collage .carousel .control-dots .dot.selected:before {
  position: absolute;
  content: "";
  border: 2px solid #ffffff;
  width: 20px;
  height: 20px;
  left: -8px;
  top: -8px;
  border-radius: 50%;
}
.home_collage .carousel .c_item {
  display: grid;
  grid-template-columns: 2fr 5fr 4fr 1fr;
  grid-template-rows: 2fr 5fr 1fr;
  height: 100%;
}
.home_collage .carousel .c_item .message {
  grid-column-start: 2;
  grid-row-start: 2;
  color: #ffffff;
  display: grid;
}
.home_collage .carousel .c_item .message .mini_message {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: capitalize;
  position: relative;
}
.home_collage .carousel .c_item .message .mini_message:after {
  content: " ";
  position: absolute;
  top: 32px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.home_collage .carousel .c_item .message .title h2 {
  font-size: 90px;
  font-weight: 700;
  line-height: 117px;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: capitalize;
  margin-top: 1.5rem;
}
.home_collage .carousel .c_item .message .description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #AAAAAA;
  max-width: 453px;
}
.home_collage .carousel .c_item .message a {
  display: flex;
}
.home_collage .carousel .c_item .image_wrapper {
  grid-column-start: 3;
  grid-row-start: 2;
  width: 360px;
  height: 397px;
  position: relative;
}
.home_collage .carousel .c_item .image_wrapper img {
  width: 360px;
  height: 397px;
  border: 14px solid transparent;
}
.home_collage .carousel .c_item .image_wrapper:before,
.home_collage .carousel .c_item .image_wrapper:after {
  content: "";
  position: absolute;
  width: 250px;
  height: 250px;
  background-color: #d4e9e2;
  z-index: -1;
}
.home_collage .carousel .c_item .image_wrapper:before {
  bottom: 0;
}
.home_collage .carousel .c_item .image_wrapper:after {
  right: 0;
}
@media screen and (max-width: 75em) {
  .home_collage .carousel .c_item {
    grid-template-columns: 1fr 5fr 2fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
  }
  .home_collage .carousel .c_item .message .title h2 {
    font-size: calc(0.06 * 100vw);
    line-height: normal;
  }
  .home_collage .carousel .c_item .image_wrapper {
    width: calc(0.32 * 100vw);
    height: calc(0.36 * 100vw);
  }
  .home_collage .carousel .c_item .image_wrapper img {
    width: calc(0.32 * 100vw);
    height: calc(0.36 * 100vw);
    border: 8px solid transparent;
  }
  .home_collage .carousel .c_item .image_wrapper:before,
  .home_collage .carousel .c_item .image_wrapper:after {
    width: calc(0.2 * 100vw);
    height: calc(0.2 * 100vw);
  }
}
@media screen and (max-width: 48em) {
  .home_collage {
    height: 115vh;
  }
  .home_collage .phone {
    top: 30px;
    font-size: 90%;
  }
  .home_collage .carousel .c_item {
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr 1fr 3fr 1fr;
    grid-row-gap: 3rem;
  }
  .home_collage .carousel .c_item .message {
    grid-row-start: 3;
  }
  .home_collage .carousel .c_item .message .title h2 {
    font-size: 45px;
  }
  .home_collage .carousel .c_item .image_wrapper {
    grid-column-start: 2;
    width: 100%;
    height: 100%;
  }
  .home_collage .carousel .c_item .image_wrapper img {
    width: 100%;
    height: 100%;
    border: 8px solid transparent;
  }
  .home_collage .carousel .c_item .image_wrapper:before,
  .home_collage .carousel .c_item .image_wrapper:after {
    width: 50%;
    height: 50%;
  }
  .home_collage .carousel .control-dots {
    padding-right: 0;
    bottom: 0;
  }
}
.home_experience {
  background: #1E3932;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 3fr;
  position: relative;
}
.home_experience:before {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 60%;
  z-index: 0;
  background: url("./Assets/background-leaf.png") center no-repeat;
  left: 0;
}
.home_experience:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 40%;
  z-index: 0;
  background: url("./Assets/background-tree.png") center no-repeat;
  right: 0;
}
.home_experience .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.home_experience .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
}
.home_experience .title:after {
  content: " ";
  position: absolute;
  bottom: 56px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.home_experience .content {
  z-index: 2;
  position: relative;
  display: grid;
  grid-template-columns: 2fr 5fr 5fr 6fr 2fr;
  grid-gap: 1rem;
  grid-auto-rows: min-content;
  align-items: center;
}
.home_experience .content .img-1 {
  grid-column-start: 2;
  justify-self: end;
  width: calc(100vw * 0.2);
}
.home_experience .content .img-2 {
  grid-column-start: 3;
  width: calc(100vw * 0.2);
}
.home_experience .content .description p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #F9F9F9;
  margin-bottom: 2rem;
}
@media screen and (max-width: 75em) {
  .home_experience .content {
    grid-template-columns: 1fr 4fr 4fr 6fr 1fr;
  }
  .home_experience .content .img-1,
  .home_experience .content .img-2 {
    width: calc(100vw * 0.2);
  }
  .home_experience button {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 956px) {
  .home_experience {
    height: auto;
  }
  .home_experience .content {
    grid-template-columns: 1fr 4fr 5fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .home_experience .content .description {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 4;
  }
}
@media screen and (max-width: 48em) {
  .home_experience {
    grid-template-rows: auto auto;
  }
  .home_experience .title h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 41.6px;
    margin-bottom: 2rem;
  }
  .home_experience .content {
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: auto auto auto;
  }
  .home_experience .content .img-1 {
    grid-row-start: 1;
  }
  .home_experience .content .img-2 {
    grid-row-start: 2;
    grid-column-start: 2;
  }
  .home_experience .content .img-1,
  .home_experience .content .img-2 {
    width: auto;
  }
  .home_experience .content .description {
    grid-row-start: 3;
    grid-column-end: 2;
    padding-bottom: 4rem;
  }
}
.home_highlight {
  background: #1E3932;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  position: relative;
  overflow: hidden;
}
.home_highlight img {
  width: auto;
  height: 100%;
  overflow: hidden;
}
.home_highlight .img_1 {
  grid-row-start: 1;
  grid-column-start: 1;
  justify-self: end;
}
.home_highlight .img_2 {
  grid-row-start: 2;
  grid-column-start: 2;
}
.home_highlight .content {
  position: relative;
  color: #ffffff;
  display: grid;
  grid-template-rows: 1fr 1fr 4fr 1fr;
  padding-bottom: 2rem;
  justify-items: center;
}
.home_highlight .content h3 {
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
  grid-row-start: 2;
  position: relative;
  z-index: 2;
}
.home_highlight .content h3:before {
  content: " ";
  position: absolute;
  bottom: 70px;
  height: 78px;
  width: 78px;
  z-index: 0;
  background: url("./Assets/highlight-1.png") center no-repeat;
  left: calc(50% - 50px);
  margin: auto;
}
.home_highlight .content .description {
  grid-row-start: 3;
  justify-self: end;
  margin-top: 2rem;
  z-index: 2;
}
.home_highlight .content .description p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  padding: 0 6rem;
  color: #F9F9F9;
}
.home_highlight .content button {
  grid-row-start: 4;
  align-self: end;
  text-align: center;
  z-index: 2;
}
.home_highlight .content:before {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: rgba(255, 255, 255, 0.2);
  left: 0;
}
.home_highlight .content:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 26%;
  z-index: 0;
  background: url("./Assets/highlight-bg-1.png") center no-repeat;
  right: 0;
}
.home_highlight .content.content_2 h3:before {
  content: " ";
  position: absolute;
  bottom: 70px;
  height: 90px;
  width: 130px;
  z-index: 0;
  background: url("./Assets/highlight-2.png") center no-repeat;
  left: calc(50% - 65px);
  margin: auto;
  background-size: contain;
}
.home_highlight .content.content_2:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 36%;
  z-index: 0;
  background: url("./Assets/highlight-bg-2.png") center no-repeat;
  left: 0;
}
@media screen and (max-width: 1580px) {
  .home_highlight .content .description p {
    padding: 0 2rem;
    margin-top: 0;
  }
}
@media screen and (max-width: 1026px) {
  .home_highlight {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .home_highlight .img_1,
  .home_highlight .img_2 {
    grid-column-start: 1;
  }
  .home_highlight .img_2 {
    grid-row-start: 3;
  }
  .home_highlight .content {
    grid-column-start: 1;
  }
}
@media screen and (max-width: 514px) {
  .home_highlight .content {
    grid-template-rows: auto;
  }
  .home_highlight .content h3::before {
    opacity: 0.5;
  }
  .home_highlight button {
    margin-top: 2rem;
  }
}
.home_specialties {
  background: #1E3932;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 3fr;
  position: relative;
}
.home_specialties:before {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 60%;
  z-index: 0;
  background: url("./Assets/background-leaf.png") center no-repeat;
  left: 0;
}
.home_specialties .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.home_specialties .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
}
.home_specialties .title:after {
  content: " ";
  position: absolute;
  bottom: 30px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.home_specialties .items {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 6fr 6fr 1fr;
  grid-column-gap: 2rem;
  grid-auto-rows: min-content;
  grid-template-rows: 71px 7fr 1fr;
}
.home_specialties .items .item img {
  width: 100%;
}
.home_specialties .items .item .content {
  color: #AAAAAA;
  padding-top: 1.5rem;
}
.home_specialties .items .item .content p {
  font-family: 'Open Sans', sans-serif;
}
.home_specialties .items .item .content .item_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
}
.home_specialties .items .item .content .item_description {
  padding-top: 0.7rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
}
.home_specialties .items .item_1 {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.home_specialties .items .item_2 {
  grid-column-start: 3;
  grid-row-start: 2;
}
.home_specialties .items .item_3 {
  grid-column-start: 4;
  grid-row-start: 1;
}
.home_specialties .items .item_4 {
  grid-column-start: 5;
  grid-row-start: 2;
}
@media screen and (max-width: 1026px) {
  .home_specialties {
    height: auto;
    grid-template-rows: 1fr 5fr;
  }
  .home_specialties .items {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
  .home_specialties .items .item {
    min-width: 17rem;
  }
}
.home_menu {
  background: #1E3932;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 5fr;
  position: relative;
}
.home_menu:before {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 60%;
  z-index: 0;
  background: url("./Assets/background-leaf.png") center no-repeat;
  left: 0;
}
.home_menu .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.home_menu .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
}
.home_menu .title:after {
  content: " ";
  position: absolute;
  bottom: 30px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.home_menu .carousel.carousel-slider {
  display: grid;
}
.home_menu .menu {
  display: grid;
  z-index: 1;
  position: relative;
}
.home_menu .menu .categories ul {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 5rem;
  list-style-type: none;
}
.home_menu .menu .categories ul li {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #D4E9E2;
  cursor: pointer;
  position: relative;
}
.home_menu .menu .categories ul li:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 0%;
  height: 2px;
  left: 0;
  bottom: -10px;
  transition: all 0.3s ease;
}
.home_menu .menu .categories ul li.active:before,
.home_menu .menu .categories ul li:hover:before {
  width: 100%;
}
.home_menu .menu .items_container .items {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  margin: 0 10rem 3rem 10rem;
  grid-gap: 2rem;
}
.home_menu .menu .items_container .items .item {
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: column;
  align-items: center;
}
.home_menu .menu .items_container .items .item img {
  width: 176px;
  height: 176px;
  justify-self: end;
}
.home_menu .menu .items_container .items .item .content {
  justify-self: start;
}
.home_menu .menu .items_container .items .item .content .content_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}
.home_menu .menu .items_container .items .item .content .description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #AAAAAA;
  max-width: 390px;
}
.mini_events {
  background: #1E3932;
  padding-bottom: 3rem;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 4fr;
  position: relative;
}
.mini_events .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.mini_events .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
}
.mini_events .title:after {
  content: " ";
  position: absolute;
  bottom: 30px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.mini_events .items {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 4rem;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: start;
}
.footer_location {
  background: transparent;
  height: 70vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 4fr;
  position: relative;
  z-index: 1;
}
.footer_location .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
}
.footer_location .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: center;
}
.footer_location .title:after {
  content: " ";
  position: absolute;
  bottom: 30px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.footer_location .map_wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 4rem;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: start;
}
.footer_location .map_wrapper .buttons ul {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  grid-gap: 5rem;
  list-style-type: none;
  padding: 0;
}
.footer_location .map_wrapper .buttons ul li button {
  background-color: transparent;
  border: none;
  width: 210px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}
.footer_location .map_wrapper .buttons ul li button:before {
  content: " ";
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 1;
  left: -75px;
  height: 65px;
  width: 65px;
  background: url("./Assets/map-pin.png") center no-repeat;
}
.footer_location .map_wrapper .buttons ul li button:after {
  content: " ";
  position: absolute;
  bottom: 0;
  top: 0;
  left: -75px;
  height: 65px;
  width: 65px;
  background: #AAAAAA;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.footer_location .map_wrapper .buttons ul li button:hover:after {
  background: #00754A;
}
.footer_location .map_wrapper .buttons.buttons_right ul li button {
  text-align: right;
}
.footer_location .map_wrapper .buttons.buttons_right ul li button::before,
.footer_location .map_wrapper .buttons.buttons_right ul li button:after {
  left: 105%;
}
.footer_location .map_wrapper .map-container {
  width: 590px;
  height: 460px;
}
.footer {
  background: none;
  height: 50vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 2fr 3fr 1fr;
  position: relative;
  overflow: visible;
}
.footer:before {
  position: absolute;
  width: 100%;
  height: 140%;
  content: " ";
  background: url("./Assets/chaka-footer-bg.png") center no-repeat;
  background-size: 100%;
  margin-top: -190px;
  bottom: -15px;
}
.footer:after {
  position: absolute;
  background: linear-gradient(180deg, #1E3932 45%, rgba(0, 117, 74, 0) 152%);
  width: 100%;
  height: 267%;
  content: " ";
  background-size: 100%;
  margin-top: -750px;
}
.footer .footer_main {
  z-index: 2;
  grid-row-start: 2;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
}
.footer .footer_main .footer_contact_us {
  justify-self: end;
}
.footer .footer_main .footer_contact_us h6 {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
}
.footer .footer_main .footer_contact_us p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
}
.footer .footer_main .footer_social_media {
  justify-self: center;
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
}
.footer .footer_main .footer_social_media p {
  grid-row-start: 2;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff;
  position: relative;
}
.footer .footer_main .footer_social_media p:after {
  content: " ";
  position: absolute;
  bottom: 30px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
  left: 0;
  right: 0;
  margin: auto;
}
.footer .footer_main .footer_social_media .links {
  grid-row-start: 3;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 3rem;
}
.footer .footer_main .footer_working_hours {
  justify-self: start;
}
.footer .footer_main .footer_working_hours h6 {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
}
.footer .footer_main .footer_working_hours p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff;
}
.footer .footer_copyright {
  grid-row-start: 3;
}
.footer .footer_copyright p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff;
}
.event_item {
  max-width: 386px;
  position: relative;
}
.event_item img {
  width: 100%;
}
.event_item:before {
  content: " ";
  position: absolute;
  top: -25px;
  left: -25px;
  height: calc(100% + 50px);
  width: calc(100% + 50px);
  background: transparent;
  transition: all 0.2s ease;
}
.event_item:hover:before {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.event_item .content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.event_item .content .title_date {
  display: flex;
}
.event_item .content .title_date .date_wrapper {
  min-width: 45px;
  margin-right: 10px;
}
.event_item .content .title_date .date_wrapper .month {
  color: #00754A;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 42px;
}
.event_item .content .title_date .date_wrapper .date {
  color: #D4E9E2;
  font-size: 23px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.04em;
  text-align: center;
}
.event_item .content .title_date .item_title {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 1.5rem;
  color: #ffffff;
}
.event_item .content .item_description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  color: #AAAAAA;
  margin-bottom: 1.5rem;
}
.event_item .content button {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: #F2F0EB;
  text-decoration: none;
}
.event_item .content button:hover {
  text-decoration: underline;
}
.about_us_history {
  background: #1E3932;
  height: 110vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 4fr;
  position: relative;
  grid-gap: 3rem;
}
.about_us_history:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: url("./Assets/bg-tree-full.png") center no-repeat;
  right: 0;
}
.about_us_history .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.about_us_history .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: capitalize;
}
.about_us_history .title p {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #AAAAAA;
}
.about_us_history .title:after {
  content: " ";
  position: absolute;
  bottom: -20px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.about_us_history .history {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 2fr;
  grid-template-rows: 1fr 1fr 2fr;
  grid-gap: 3rem;
}
.about_us_history .history img {
  width: 100%;
}
.about_us_history .history .short_history {
  grid-column-start: 2;
  grid-row-start: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #AAAAAA;
}
.about_us_history .history .img-1 {
  grid-column-start: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.about_us_history .history .stats {
  grid-column-start: 3;
  color: #D4E9E2;
}
.about_us_history .history .stats h4 {
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: left;
}
.about_us_history .history .stats .data {
  display: grid;
  grid-auto-flow: column;
}
.about_us_history .history .stats .data .stat .stat_num {
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 1.5rem;
}
.about_us_history .history .stats .data .stat .stat_desc {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: left;
  max-width: 130px;
}
.about_us_history .history .img-2 {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 4;
}
.about_us_vid_section {
  position: relative;
}
.about_us_vid_section .pause_play {
  position: absolute;
  background: transparent url("./Assets/play-button.png") no-repeat center;
  background-position: 57% 51%;
  border: 1px solid #ffffff;
  top: calc(50% - 1.5px);
  left: calc(50% - 3rem);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  line-height: 1rem;
  z-index: 2;
  content: "";
  text-indent: -100000px;
  cursor: pointer;
}
.about_us_vid_section:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  z-index: 0;
  left: 0;
  background: #0c0c0c;
  opacity: 0.6;
}
.about_us_vid_section video {
  background: #1E3932;
}
.about_us_vid_section.playing .pause_play {
  display: none;
}
.about_us_vid_section.playing:before {
  display: none;
}
.events_page .main_content {
  background: #1E3932;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  position: relative;
  grid-gap: 3rem;
}
.events_page .main_content:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: url("./Assets/bg-tree-full.png") center no-repeat;
  right: 0;
}
.events_page .main_content .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.events_page .main_content .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: capitalize;
}
.events_page .main_content .title:after {
  content: " ";
  position: absolute;
  bottom: -20px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.events_page .main_content .search_events {
  z-index: 1;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 2fr;
}
.events_page .main_content .search_events .search_bar_wrapper {
  grid-column-start: 2;
  margin: 0 20px;
  position: relative;
  max-height: 50px;
}
.events_page .main_content .search_events .search_bar_wrapper:before {
  content: " ";
  position: absolute;
  top: 12px;
  left: 15px;
  height: 25px;
  width: 25px;
  background: url("./Assets/Search.png") center no-repeat;
}
.events_page .main_content .search_events .search_bar_wrapper input {
  font-family: 'Open Sans', sans-serif;
  padding: 0.5rem 4rem;
  width: 100%;
  border: 2px solid #D4E9E2;
  border-radius: 24px;
  background-color: transparent;
  color: #ffffff;
  font-size: 20px;
  outline: none;
}
.events_page .main_content .search_events .search_bar_wrapper input::placeholder {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(249, 249, 249, 0.2);
}
.events_page .main_content .search_events .search_bar_wrapper input:focus {
  -moz-box-shadow: 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #fff;
  box-shadow: 0px 0px 5px #fff;
}
.events_page .main_content .search_events select {
  font-family: 'Open Sans', sans-serif;
  max-height: 50px;
  margin: 0 20px;
  border: none;
  border-radius: 24px;
  background-color: rgba(249, 249, 249, 0.2);
  color: #F9F9F9;
  padding: 0.7rem 0.7rem 0.7rem 2.6rem;
  text-align: left;
  outline: none;
  background: rgba(249, 249, 249, 0.2) url("./Assets/dropdown-arrow.png") no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: 150px 23px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
}
.events_page .main_content .search_events select option {
  font-family: 'Open Sans', sans-serif;
  height: 50px;
  margin: 0 20px;
  border: none;
  color: #00754A;
  padding: 0.7rem 0.7rem 0.7rem 2.6rem;
}
.events_page .main_content .items {
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  grid-auto-rows: min-content;
  justify-content: center;
  align-content: start;
}
.events_page .main_content .button_wrapper {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 4rem;
}
.event_detail .header {
  height: 100vh;
  width: 100%;
  display: grid;
}
.event_detail .header:before {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: "";
  z-index: 1;
}
.event_detail .header img {
  width: 100%;
  height: 100vh;
  position: absolute;
}
.event_detail .header .header_content {
  color: #ffffff;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 4fr 4fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.event_detail .header .header_content .general {
  grid-row-start: 2;
  grid-column-start: 2;
  max-width: 420px;
}
.event_detail .header .header_content .general h4 {
  color: #F2F0EB;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 1rem;
}
.event_detail .header .header_content .general h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #D4E9E2;
  margin-bottom: 2rem;
}
.event_detail .header .header_content .general p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #AAAAAA;
}
.event_detail .header .header_content .when_where {
  grid-row-start: 2;
  grid-column-start: 3;
  display: grid;
  grid-template-rows: 2fr 7fr 2fr;
  max-width: 360px;
  padding: 45px;
  border-radius: 10px;
  background-color: rgba(74, 95, 90, 0.7);
}
.event_detail .header .header_content .when_where h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
}
.event_detail .header .header_content .when_where .date_location {
  color: #AAAAAA;
}
.event_detail .header .header_content .when_where .date_location .date_time {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  padding-bottom: 0.9rem;
  position: relative;
  padding-left: 30px;
}
.event_detail .header .header_content .when_where .date_location .date_time:before {
  background: url("./Assets/clock.png") center no-repeat;
  position: absolute;
  height: 24px;
  width: 24px;
  top: 2px;
  content: "";
  left: -3px;
  z-index: 1;
}
.event_detail .header .header_content .when_where .date_location .location {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  position: relative;
  padding-left: 30px;
}
.event_detail .header .header_content .when_where .date_location .location:before {
  background: url("./Assets/map-pin-1.png") center no-repeat;
  position: absolute;
  width: 18px;
  height: 22px;
  top: 2px;
  left: 0;
  content: "";
  z-index: 1;
}
.event_detail .header .header_content .when_where .button_wrapper button {
  width: 100%;
}
.event_detail .description_location {
  height: 80vh;
  width: 100%;
  display: grid;
  background: #1E3932;
  grid-template-rows: 1fr 1fr 9fr;
  grid-template-columns: 2fr 9fr 2fr;
  grid-gap: 2rem;
}
.event_detail .description_location h3 {
  grid-column-start: 2;
  grid-row-start: 2;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #ffffff;
}
.event_detail .description_location .content {
  grid-column-start: 2;
  grid-row-start: 3;
  display: grid;
  grid-gap: 3rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
}
.event_detail .description_location .content .description {
  display: grid;
  color: #AAAAAA;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-top: 1rem;
}
.event_detail .description_location .content .location {
  display: grid;
  grid-template-rows: 1fr 9fr;
}
.event_detail .description_location .content .location h4 {
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;
  padding-bottom: 1rem;
}
.event_detail .description_location .content .location .map_wrapper {
  width: 100%;
  height: 100%;
}
.event_detail .description_location .content .location .map_wrapper .map_container {
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.menu_page .main_content {
  background: #1E3932;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 2fr 8fr 2fr;
  position: relative;
  grid-row-gap: 7rem;
  padding-top: 4rem;
}
.menu_page .main_content:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: url("./Assets/menu-page-bg.png") right repeat-y;
  right: 0;
  opacity: 0.6;
}
.menu_page .main_content .search_filter {
  grid-column-start: 2;
  z-index: 1;
}
.menu_page .main_content .search_filter .search_bar_wrapper {
  margin: 0 20px;
  position: relative;
  max-height: 50px;
  margin-bottom: 2rem;
}
.menu_page .main_content .search_filter .search_bar_wrapper:before {
  content: " ";
  position: absolute;
  top: 12px;
  left: 15px;
  height: 25px;
  width: 25px;
  background: url("./Assets/Search.png") center no-repeat;
}
.menu_page .main_content .search_filter .search_bar_wrapper input {
  font-family: 'Open Sans', sans-serif;
  padding: 0.5rem 4rem;
  width: 100%;
  border: 2px solid #D4E9E2;
  border-radius: 24px;
  background-color: transparent;
  color: #ffffff;
  font-size: 20px;
  outline: none;
}
.menu_page .main_content .search_filter .search_bar_wrapper input::placeholder {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(249, 249, 249, 0.2);
}
.menu_page .main_content .search_filter .search_bar_wrapper input:focus {
  -moz-box-shadow: 0 0 5px #fff;
  -webkit-box-shadow: 0 0 5px #fff;
  box-shadow: 0px 0px 5px #fff;
}
.menu_page .main_content .search_filter .location_filter {
  display: flex;
  flex-wrap: wrap;
}
.menu_page .main_content .search_filter .location_filter a {
  font-family: 'Open Sans', sans-serif;
  max-height: 50px;
  margin: 0 20px;
  border: none;
  border-radius: 24px;
  background-color: rgba(249, 249, 249, 0.2);
  color: #F9F9F9;
  padding: 10px 20px;
  flex-basis: auto;
  margin-top: 1rem;
}
.menu_page .main_content .search_filter .location_filter a.active {
  background-color: #00754A;
}
.menu_page .main_content .menu_list {
  z-index: 1;
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 3fr 9fr;
}
.menu_page .main_content .menu_list .meal_filter ul {
  display: grid;
  grid-auto-flow: row;
  justify-content: left;
  grid-row-gap: 2rem;
  list-style-type: none;
  padding: 0;
  margin: 0 20px;
}
.menu_page .main_content .menu_list .meal_filter ul li a {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #D4E9E2;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
}
.menu_page .main_content .menu_list .meal_filter ul li a:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 0%;
  height: 2px;
  left: 0;
  bottom: -10px;
  transition: all 0.3s ease;
}
.menu_page .main_content .menu_list .meal_filter ul li a.active:before,
.menu_page .main_content .menu_list .meal_filter ul li a:hover:before {
  width: 100%;
}
.menu_page .main_content .menu_list .item_list {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 3rem;
  justify-content: space-between;
}
.menu_page .main_content .menu_list .item_list .item {
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: row;
  align-self: center;
  align-items: center;
  max-width: 200px;
}
.menu_page .main_content .menu_list .item_list .item img {
  width: 176px;
  height: 176px;
  justify-self: center;
  border-radius: 50%;
}
.menu_page .main_content .menu_list .item_list .item .content {
  justify-self: center;
}
.menu_page .main_content .menu_list .item_list .item .content .content_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}
.menu_page .main_content .menu_list .item_list .item .content .description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: #AAAAAA;
  max-width: 390px;
}
.contact_us {
  background: url("./Assets/contact-us-bg.png") center no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
.contact_us:before {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: "";
  z-index: 1;
}
.contact_us .left {
  z-index: 2;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
}
.contact_us .left .title {
  grid-column-start: 2;
  color: #ffffff;
}
.contact_us .left .title h2 {
  font-size: 90px;
  font-weight: 700;
  line-height: 117px;
  letter-spacing: 0.04em;
  text-align: left;
}
.contact_us .left .title p {
  font-size: 45px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0.04em;
  margin-top: 1rem;
  text-align: left;
}
.contact_us .left .form {
  grid-column-start: 2;
  display: grid;
  align-self: self-start;
  row-gap: 3rem;
}
.contact_us .left .form input,
.contact_us .left .form textarea {
  background: rgba(54, 68, 64, 0.69);
  border: 1px solid #364440;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.9);
  padding: 18px 18px 18px 20px;
}
.contact_us .left .form input::placeholder,
.contact_us .left .form textarea::placeholder {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(255, 255, 255, 0.9);
}
.contact_us .left .form input {
  width: 370px;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
}
.contact_us .left .form input.name {
  background: rgba(54, 68, 64, 0.8);
}
.contact_us .left .form textarea {
  width: 440px;
  height: 140px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
}
.contact_us .right {
  z-index: 2;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 5fr 3fr 3fr;
  color: #ffffff;
}
.contact_us .right > div {
  grid-column-start: 1;
  align-self: start;
}
.contact_us .right > div h3 {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.04em;
  text-align: center;
  padding-bottom: 1rem;
  margin-top: -2rem;
}
.contact_us .right > div p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
}
.contact_us .right .hours {
  grid-row-start: 2;
}
.contact_us .right .contacts {
  grid-row-start: 3;
}
.location_page {
  background: #1E3932;
  height: 100%;
  width: 100%;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
}
.location_page:after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: url("./Assets/bg-tree-full.png") center no-repeat;
  right: 0;
  background-position-x: 75vw;
}
.location_page .main_content {
  z-index: 1;
  display: grid;
  grid-gap: 4rem;
}
.location_page .main_content .title {
  justify-content: center;
  display: flex;
  margin-top: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
}
.location_page .main_content .title h2 {
  max-width: 700px;
  color: #ffffff;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: capitalize;
}
.location_page .main_content .title p {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #AAAAAA;
}
.location_page .main_content .title:after {
  content: " ";
  position: absolute;
  bottom: -20px;
  height: 10px;
  width: 40px;
  background: url("./Assets/title-spoon.png") center no-repeat;
}
.location_page .main_content .location_list {
  display: grid;
  grid-gap: 5rem;
}
.location_page .main_content .location_list .location_item {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 2fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  position: relative;
}
.location_page .main_content .location_list .location_item .image_title_wrapper {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}
.location_page .main_content .location_list .location_item .image_title_wrapper h3 {
  position: absolute;
  z-index: 1;
  height: auto;
  top: 10%;
  left: 35%;
  width: 700px;
  font-size: 64px;
  font-weight: 600;
  line-height: 83px;
  letter-spacing: 0.03em;
  text-align: left;
  background: #1E3932;
  color: #F9F9F9;
  padding-left: 2rem;
}
.location_page .main_content .location_list .location_item .image_title_wrapper img {
  width: 100%;
}
.location_page .main_content .location_list .location_item .desc_link_wrapper {
  grid-column-start: 3;
  grid-row-start: 2;
  align-items: end;
  display: grid;
  padding-left: 2rem;
}
.location_page .main_content .location_list .location_item .desc_link_wrapper p {
  max-width: 340px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #AAAAAA;
}
.location_page .main_content .location_list .location_item .desc_link_wrapper a {
  align-self: baseline;
  margin-top: 2rem;
}
.location_page .main_content .location_list .location_item.lc_odd .image_title_wrapper {
  grid-column-start: 3;
}
.location_page .main_content .location_list .location_item.lc_odd .image_title_wrapper h3 {
  right: 35%;
  left: auto;
  padding-left: unset;
  padding-right: 2rem;
  text-align: right;
}
.location_page .main_content .location_list .location_item.lc_odd .desc_link_wrapper {
  grid-column-start: 2;
  padding-left: unset;
  padding-right: 2rem;
  display: grid;
  justify-items: end;
}
.location_page .main_content .location_list .location_item.lc_odd .desc_link_wrapper p {
  text-align: right;
}
